/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import * as PropTypes from 'prop-types'
import { graphql, useStaticQuery } from 'gatsby'
import { ParallaxProvider } from 'react-scroll-parallax'

import { Hero } from './Hero'
import './layout.scss'

export const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ParallaxProvider>
        <Hero />
        <div>
          <main>{children}</main>
          <footer className="py-3">
            <div className="container">
              © {new Date().getFullYear()}, Projexive FZ LLC All Rights Reserved
              {` `}
            </div>
          </footer>
        </div>
      </ParallaxProvider>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}
