import * as React from 'react'

export const Logo = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143 71" {...props}>
    <title>logo</title>
    <g>
      <path
        d="M8.71,9.31H14.8a7,7,0,0,1,4.3,1.15c1,.76,1.87,2.5,2.56,5.2a41.19,41.19,0,0,1,1,10,44.8,44.8,0,0,1-.84,9.52,12.35,12.35,0,0,1-2.31,5.43A5.76,5.76,0,0,1,15,42.31H13.36V61.69H8.71Zm4.65,8.48V33.33H14.8a2.43,2.43,0,0,0,2.45-2.19,23.93,23.93,0,0,0,.55-5.7,22.16,22.16,0,0,0-.42-4.79,5,5,0,0,0-1-2.34,2.26,2.26,0,0,0-1.59-.52Z"
        style={{
          fill: '#44629e'
        }}
      />
      <path
        d="M24.93,9.31h6.64a7.7,7.7,0,0,1,3,.44,5,5,0,0,1,2.08,2.38,17.3,17.3,0,0,1,1.65,5.29,43.16,43.16,0,0,1,.6,7.54q0,9.83-3,13.82l3.88,22.91H34.73L31.9,41H29.58V61.69H24.93Zm4.65,8.48v14.7h2q2.73,0,2.73-7.56a18.6,18.6,0,0,0-.41-4.28A5.49,5.49,0,0,0,33,18.38a2,2,0,0,0-1.57-.59Z"
        style={{
          fill: '#44629e'
        }}
      />
      <path
        d="M55.68,35.48q0,27-7.66,27-3.81,0-5.75-6.89T40.33,35.48q0-27,7.67-27,3.75,0,5.72,6.75T55.68,35.48Zm-4.89,0q0-10.59-.67-14.1C49.68,19,49,17.86,48,17.86S46.34,19,45.89,21.4s-.66,7-.66,14.08.22,11.58.66,14S47,53.14,48,53.14s1.67-1.17,2.12-3.5S50.79,42.59,50.79,35.48Z"
        style={{
          fill: '#44629e'
        }}
      />
      <path
        d="M60.66,8.5H65.3V43.8q0,6.74-.39,10.5a15.77,15.77,0,0,1-1.64,6A4,4,0,0,1,59.6,62.5a7.28,7.28,0,0,1-2.83-.82V51.9a4.39,4.39,0,0,0,1.87.55c.82,0,1.36-.55,1.62-1.66a38.13,38.13,0,0,0,.4-7Z"
        style={{
          fill: '#44629e'
        }}
      />
      <path
        d="M80.84,37.89H72.65v2.87a48.22,48.22,0,0,0,.57,9.31c.38,1.58.88,2.36,1.49,2.36q2,0,2.24-8.84l3.89.82q-1,18.09-6.2,18.09c-2.13,0-3.74-2.38-4.85-7.14s-1.65-11.13-1.65-19.11q0-13.51,1.75-20.63c1.17-4.75,2.75-7.12,4.71-7.12q2.67,0,4.45,6.29T80.84,37.89Zm-4.12-8.38c0-.61,0-1.09,0-1.43q0-10-2-10t-2,11.45Z"
        style={{
          fill: '#44629e'
        }}
      />
      <path
        d="M81.94,8.5h5.18l2.52,13.79L92.3,8.5h4.45l-5,24.06L97.4,61.7H92.3L89.22,44.37,85.76,61.7H81.3l5.81-27.26Z"
        style={{
          fill: '#44629e'
        }}
      />
      <path
        d="M98.92,8.5h4.64V61.7H98.92Z"
        style={{
          fill: '#44629e'
        }}
      />
      <path
        d="M105.13,8.5H110l3.25,40.56L116.62,8.5h4L115.41,62l-5.2-.28Z"
        style={{
          fill: '#44629e'
        }}
      />
      <path
        d="M134.29,37.89h-8.2v2.87a47.13,47.13,0,0,0,.58,9.31c.38,1.58.88,2.36,1.49,2.36q2,0,2.24-8.84l3.89.82q-1,18.09-6.2,18.09c-2.13,0-3.74-2.38-4.85-7.14s-1.65-11.13-1.65-19.11q0-13.51,1.75-20.63t4.71-7.12q2.67,0,4.45,6.29T134.29,37.89Zm-4.12-8.38c0-.61,0-1.09,0-1.43q0-10-2-10t-2,11.45Z"
        style={{
          fill: '#44629e'
        }}
      />
    </g>
    <path
      d="M139,4V67H4V4H139m4-4H0V71H143V0Z"
      style={{
        fill: '#8eb097'
      }}
    />
  </svg>
)
