import * as React from 'react'
import classNames from 'classnames'

import { ParallaxBanner } from 'react-scroll-parallax'
import splashBg from '../images/highrises.jpg'
import * as styles from './Hero.module.scss'
import { Logo } from '../components/Logo'

export const Hero = () => (
  <header id="header">
    <ParallaxBanner
      className={styles.intro}
      layers={[
        {
          image: splashBg,
          amount: 0.3,
          children: <div className={styles.overlay} />
        },
        {
          amount: 0.8,
          expanded: false,
          children: (
            <div className="container">
              <div className="row">
                <div
                  className={classNames(
                    'col-md-8 col-md-offset-2',
                    styles.introText
                  )}
                >
                  <h1>
                    <Logo className={styles.logoImage} />
                    <br />
                    Engineering
                    <span />
                  </h1>
                </div>
              </div>
            </div>
          )
        }
      ]}
      style={{
        minHeight: '74vh'
      }}
    />
    {/*<div
      className={styles.intro}
      style={{
        backgroundImage: `url(${splashBg})`
      }}
    ></div>*/}
  </header>
)
